import * as React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { useStaticQuery, graphql } from "gatsby"

const FlyerPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulResourcePage {
        imagesSection {
          fileUpload {
            file {
              url
            }
            title
          }
        }
      }
    }
  `)
  const imgContent = data.contentfulResourcePage.imagesSection.fileUpload;
  const style = {
    width: '350px',
    height: '250px',
    objectFit: 'cover'
  }
  return (
    <Layout>
      <SEO title="Resources" />
      <div className="sidebar-page-container">
        <div className="services-section">
          <div className="auto-container">
            <div className="sec-title">
              <div className="title">tekki fii</div>
              <h2><span>Resources </span>Images</h2>
            </div>
            <div className="inner-container">
              <SimpleReactLightbox>
                <SRLWrapper>
                  <div className="video">
                    <div className="tab-content">
                      <div className="row g-3">
                        {imgContent.map(({ file, title }, index) => (
                          <div className="video-block col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
                            <img className="card-img-top" src={file.url} alt={title} style={style} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div >
                </SRLWrapper>
              </SimpleReactLightbox>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default FlyerPage

